import React from 'react';
import styles from './pageLoading.module.less';
import classnames from 'classnames';
import { Icon } from 'antd-mobile';

const Footer = (restProps: any) => {
  return (
    <div
      className={classnames(
        styles['page-loading'],
        'flex',
        'flex-v',
        'flex-align-center',
        'flex-pack-center'
      )}
      {...restProps}
    >
      <img className={styles['rw-logo']} src={require('@/assets/images/蓝色logo@2x.png')} alt="" />
      <Icon type="loading" size={'lg'} />
    </div>
  );
};

export default Footer;
