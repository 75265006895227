import React, { useEffect, useCallback, useState } from 'react';
import { Tabs, Button, Modal, Toast } from 'antd-mobile';
import classname from 'classnames';
import styles from './index.module.less';
import { StickyContainer, Sticky } from 'react-sticky';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchOrderListAction } from '@/store/user/actions';
import { confirmOrder } from '@/api/apiServer/apiProfile';
import copy from 'copy-to-clipboard';
import Empty from '@/components/Empty';
import { validate } from '@/utils';

const tabs = [
  { title: '待发货', sub: '1' },
  { title: '已发货', sub: '2' },
  { title: '交易完成', sub: '4' }
];

const alert = Modal.alert;

export function renderTabBar(props: any) {
  return (
    <Sticky>
      {({ style }: any) => (
        <div style={{ ...style, zIndex: 1 }}>
          <Tabs.DefaultTabBar {...props} />
        </div>
      )}
    </Sticky>
  );
}

const PointsOrder = () => {
  const dispatch = useDispatch();

  const { queryOrderParams, orderList } = useSelector(
    (state: any) => state.userReducer,
    shallowEqual
  );

  const [curTabs, setCurTabs] = useState(0);

  const initialTable = useCallback(() => {
    dispatch(
      fetchOrderListAction({
        status: queryOrderParams.status,
        pageNum: queryOrderParams.pageNum,
        pageSize: queryOrderParams.pageSize
      })
    );
  }, [queryOrderParams.status, queryOrderParams.pageNum, queryOrderParams.pageSize, dispatch]);

  useEffect(() => {
    initialTable();
  }, [initialTable]);

  const showAlert = (data: any) => {
    const alertInstance = alert(
      '订单号',
      <div className={classname('flex', 'flex-v', 'flex-align-top')}>
        <p
          style={{ width: '100%', marginBottom: '0.2rem' }}
          className={classname('flex', 'flex-pack-justify')}
        >
          <span>物流: {data.expressCompany}</span>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              if (copy(data.expressNumber)) {
                Toast.success('复制成功', 1);
              }
            }}
          >
            复制单号
          </Button>
        </p>
        <p>订单编号: {data.expressNumber}</p>
      </div>,
      [{ text: '确 定', onPress: () => {}, style: 'default' }]
    );
    setTimeout(() => {
      // 可以调用close方法以在外部close
      console.log('auto close');
      alertInstance.close();
    }, 500000);
  };

  const showAlert2 = (data: any) => {
    const alertInstance = alert(
      '确认收货',
      <div className={classname('flex', 'flex-v', 'flex-align-top')}>
        <p style={{ padding: '0.4rem 0' }}>提示：一定要收到货后再确认收货</p>
      </div>,
      [
        { text: '取 消', onPress: () => {}, style: 'default' },
        {
          text: '已收货',
          onPress: () => {
            Toast.loading('系统处理中...', 50000);
            onOk(data);
          },
          style: 'default'
        }
      ]
    );
    setTimeout(() => {
      // 可以调用close方法以在外部close
      console.log('auto close');
      alertInstance.close();
    }, 500000);
  };

  const onOk = (data: any) => {
    setTimeout(() => {
      confirmOrder({ id: data.id }).then(({ data }) => {
        if (data.code === '00000000') {
          // 刷新列表
          initialTable();
          Toast.success('已确认收货', 2);
        } else {
          Toast.fail(data.msg, 4);
        }
      });
    }, 1500);
  };

  const handleTabClick = useCallback(
    (tab: any, index: any) => {
      setCurTabs(index);
      dispatch({
        type: 'profile/query_order_params',
        payload: {
          ...queryOrderParams,
          status: tab.sub
        }
      });
    },
    [dispatch, queryOrderParams]
  );

  return (
    <div className={classname(styles.order)}>
      <StickyContainer>
        <Tabs
          tabs={tabs}
          initialPage={0}
          renderTabBar={renderTabBar}
          onChange={handleTabClick}
          swipeable={false}
        >
          {tabs.map(x => (
            <div
              key={x.sub}
              className={classname(styles.tabBox, 'flex', 'flex-wrap', 'flex-pack-justify')}
            >
              {orderList && orderList.length > 0 ? (
                <>
                  {orderList.map((item: any) => (
                    <OrderCard
                      key={item.id}
                      data={item}
                      onView={showAlert}
                      onSure={showAlert2}
                      curTabs={curTabs}
                    />
                  ))}
                </>
              ) : (
                <Empty />
              )}
            </div>
          ))}
        </Tabs>
      </StickyContainer>
    </div>
  );
};

const OrderCard = ({ data, onView, onSure, curTabs }: any) => {
  return (
    <div className={classname(styles.goodsCard, 'flex', 'flex-v')}>
      <div className={classname('flex')}>
        <div className={classname(styles.goodsCover)} style={{ backgroundColor: '#f8f8f8' }}>
          {data.goodsInfo && data.goodsInfo.img ? (
            <img
              className={classname(styles.goodsCover)}
              src={
                validate.isUrl(data.goodsInfo.img)
                  ? data.goodsInfo.img
                  : process.env.REACT_APP_IMG_ADDR + '/' + data.goodsInfo.img
              }
              alt=""
            />
          ) : null}
        </div>
        <div
          className={classname(styles.goodsBox, 'flex', 'flex-1', 'flex-v', 'flex-pack-justify')}
        >
          <div>
            <p className={classname(styles.goodsCode)}>{data.number}</p>
            <p className={classname(styles.goodsTitle)}>
              {data.goodsInfo ? data.goodsInfo.name : null}
            </p>
          </div>
          <span className={classname(styles.goodsNum)}>
            消耗{data.goodsInfo ? data.goodsInfo.needIntegral : 0}积分
          </span>
        </div>
        <div
          className={classname(
            styles.rightBox,
            'flex',
            'flex-v',
            'flex-pack-justify',
            'flex-align-bottom'
          )}
        >
          <p>{curTabs === 0 ? '待发货' : curTabs === 1 ? '已发货' : '交易完成'}</p>
          <p>x{data.goodsNum}</p>
        </div>
      </div>
      {curTabs !== 0 && (
        <div className={classname('flex', 'flex-pack-end')} style={{ marginTop: '.24rem' }}>
          <Button type="primary" size="small" onClick={() => onView(data)}>
            查看物流单
          </Button>
          {curTabs === 1 && (
            <Button type="primary" size="small" onClick={() => onSure(data)}>
              确认收货
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default PointsOrder;
