import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, Button, Modal, Toast } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import classname from 'classnames';
import styles from './index.module.less';
import { StickyContainer } from 'react-sticky';
import { renderTabBar } from '../order/index';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchMallListAction, fetchUserInfo } from '@/store/user/actions';
import { convertMall, convertStatus } from '@/api/apiServer/apiProfile';
import Empty from '@/components/Empty';
import { validate } from '@/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loading, NoMore } from '@/components/InfiniteStatus';

const tabs = [
  { title: '全部', sub: '0' },
  // { title: '现金券', sub: '2' },
  { title: '现金余额', sub: '3' },
  { title: '实物商品', sub: '1' }
];

const alert = Modal.alert;

const PointsMall = () => {
  const [isReady, setReady] = useState(false);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const { userInfo, queryMallParams, mallList, openid, hasMore } = useSelector(
    (state: any) => state.userReducer,
    shallowEqual
  );

  useEffect(() => {
    setReady(true);
    dispatch({ type: 'refresh' });
    dispatch({
      type: 'profile/query_mall_params',
      payload: {
        type: 0,
        pageNum: 1,
        pageSize: 4
      }
    });
    // document.body.classList.add('noFix');
    return () => {
      //   document.body.classList.remove('noFix');
    };
  }, [dispatch]);

  const fetchData = useCallback(() => {
    // console.log(queryMallParams, hasMore);
    if (hasMore) {
      dispatch({
        type: 'profile/query_mall_params',
        payload: {
          ...queryMallParams,
          pageNum: queryMallParams.pageNum + 1
        }
      });
    }
  }, [hasMore, queryMallParams, dispatch]);

  const initialTable = useCallback(() => {
    if (isReady) {
      dispatch(
        fetchMallListAction({
          type: queryMallParams.type,
          pageNum: queryMallParams.pageNum,
          pageSize: queryMallParams.pageSize
        })
      );
    }
  }, [isReady, queryMallParams.type, queryMallParams.pageNum, queryMallParams.pageSize, dispatch]);

  useEffect(() => {
    initialTable();
  }, [initialTable]);

  const showAlert = (item: any = {}) => {
    const alertInstance = alert(
      '兑换现金券',
      <div>
        <p>{item.name}</p>
        <p>兑换积分： {item.needIntegral}</p>
      </div>,
      [
        { text: '取 消', onPress: () => {}, style: 'default' },
        { text: '兑 换', onPress: () => handleConvert(item) }
      ]
    );
    setTimeout(() => {
      // 可以调用close方法以在外部close
      console.log('auto close');
      alertInstance.close();
    }, 500000);
  };

  const showBalanceAlert = (item: any) => {
    const alertInstance = alert(
      '兑换余额',
      <div>
        <p>金额：{item.amounts}元</p>
        <p>兑换积分： {item.needIntegral}</p>
      </div>,
      [
        { text: '取 消', onPress: () => {}, style: 'default' },
        { text: '兑 换', onPress: () => handleBalanceConvert(item) }
      ]
    );
    setTimeout(() => {
      // 可以调用close方法以在外部close
      console.log('auto close');
      alertInstance.close();
    }, 500000);
  };

  const [curTabs, setCurTabs] = useState(0);

  const handleTabClick = useCallback(
    (tab: any, index: number) => {
      dispatch({ type: 'refresh' });
      setCurTabs(index);
      dispatch({
        type: 'profile/query_mall_params',
        payload: {
          ...queryMallParams,
          pageNum: 1,
          type: tab.sub
        }
      });
    },
    [dispatch, queryMallParams]
  );

  const toBill = () => {
    push('/pointsBill');
  };

  function handleConvert(item: any) {
    convertMall({ id: item.id, type: 2 }).then(({ data }) => {
      if (data.code === '00000000') {
        Toast.loading('兑换中...', 10000);
        setTimeout(() => handleConvertStatus(data), 1000);
      } else {
        Toast.fail('兑换失败');
      }
    });
  }

  // 余额兑换接口
  function handleBalanceConvert(item: any) {
    convertMall({ id: item.id, type: 3 }).then(({ data }) => {
      if (data.code === '00000000') {
        Toast.loading('兑换中...', 10000);
        setTimeout(() => handleConvertStatus(data), 1000);
      } else {
        Toast.fail('兑换失败');
      }
    });
  }

  function handleConvertStatus(data: any) {
    convertStatus({ id: data.data ? data.data.id : null }).then(({ data }) => {
      if (data.code === '00000000' && data.data) {
        const res = data.data;
        if (Number(res.status) === 1) {
          dispatch(fetchUserInfo({ openId: openid }));
          dispatch({ type: 'refresh' });
          initialTable();
          Toast.success('兑换成功，在个人中心查看', 2);
        } else {
          Toast.fail(res.comment);
        }
      } else {
        Toast.fail('兑换失败');
      }
    });
  }

  const handleExchange = (item: any, type: number) => {
    if (item.quantity === 0) {
      Toast.fail('兑换失败：库存不足');
      return;
    }
    if (item.category === 1) {
      // 实物商品
      // type 1 兑换 2 购买
      sessionStorage.setItem('goodsinfo', JSON.stringify(item));
      push(`/pointsMall/exchange?id=${item.id}&type=${type}`);
    } else if (item.category === 2) {
      // 现金券
      showAlert(item);
    } else {
      // 余额
      showBalanceAlert(item);
    }
  };

  //   console.log('hasMore==', hasMore);
  return (
    <div id="scrollDiv" className={classname(styles.pointsMall)}>
      <div className={classname(styles.mallBox1, 'flex', 'flex-pack-justify', 'flex-align-center')}>
        <div className={classname('flex', 'flex-align-center')}>
          <span className={styles.pointsNum}>{userInfo.integral}</span>
          <span>可用积分</span>
        </div>
        <div
          className={classname(styles.pointsBill, 'flex', 'flex-align-center', 'flex-pack-center')}
          onClick={toBill}
        >
          <span>积分账单</span>
        </div>
      </div>
      <StickyContainer>
        <Tabs
          tabs={tabs}
          initialPage={0}
          renderTabBar={renderTabBar}
          swipeable={false}
          onChange={handleTabClick}
        >
          {tabs.map(item => (
            <div key={item.sub}>
              {mallList && mallList.length > 0 ? (
                <InfiniteScroll
                  dataLength={mallList.length}
                  next={() => fetchData()}
                  // refreshFunction={fetchMoreData}
                  hasMore={hasMore}
                  loader={<Loading />}
                  endMessage={<NoMore />}
                  scrollableTarget="scrollDiv"
                  // className="works-contnet-scroll"
                >
                  <div
                    className={classname(styles.tabBox, 'flex', 'flex-wrap', 'flex-pack-justify')}
                  >
                    {mallList.map((item: any, index: number) => (
                      <GoodsCard
                        data={item}
                        num={userInfo.integral}
                        curTabs={curTabs}
                        key={index}
                        onClick={(type: number) => handleExchange(item, type)}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              ) : (
                <Empty />
              )}
            </div>
          ))}
        </Tabs>
      </StickyContainer>
    </div>
  );
};

export const GoodsCard = ({ ...restProps }: any) => {
  const { data = {}, num = 10, onClick } = restProps;
  return (
    <div className={classname(styles.goodsCard)}>
      <div className={classname(styles.goodsCover)}>
        {data.category === 1 ? (
          <>
            {data.img ? (
              <img
                className={classname(styles.goodsImgCover)}
                src={
                  validate.isUrl(data.img)
                    ? data.img
                    : process.env.REACT_APP_IMG_ADDR + '/' + data.img
                }
                alt=""
              />
            ) : null}
          </>
        ) : (
          <div className={classname(styles.goodsImgCover, styles.cashCover)}>
            <img
              className={classname(styles.goodsImgCover)}
              src={
                data.category === 3
                  ? require('@/assets/images/profile/积分商城-兑换余额@3x.png')
                  : require('@/assets/images/profile/default.jpg')
              }
              alt=""
            />
            {data.category !== 3 ? (
              <span className={classname(styles.cashNum)}>
                <span style={{ fontSize: '0.4rem' }}>&yen;</span>
                {data.amountMoney}
              </span>
            ) : null}
          </div>
        )}
      </div>
      <div className={classname(styles.goodsBox)}>
        <p className={classname(styles.goodsTitle)}>{data.name || data.amounts + '元'}</p>
        <span className={classname(styles.goodsNum)}>
          剩余
          {data.category !== 3
            ? data.quantity - (data.convert || 0)
            : data.stock - (data.convertNum || 0) || 0}
          个
        </span>
        <div className={classname('flex', 'flex-pack-justify', 'flex-align-center')}>
          <p>{data.needIntegral}积分</p>

          <Button
            disabled={num < data.needIntegral}
            type="primary"
            size="small"
            onClick={() => onClick(1)}
          >
            兑换
          </Button>
        </div>
        {data.category === 1 && data.canBusiness === 1 && (
          <div
            className={classname('flex', 'flex-pack-justify', 'flex-align-center')}
            style={{ marginTop: '.1rem', padding: '.04rem .03rem .03rem .03rem' }}
          >
            <p>&yen; {data.price}</p>

            <Button size="small" onClick={() => onClick(2)}>
              购买
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PointsMall;
